body {
  background-color: #000;
}

.App {
  max-width: 728px;
  margin: 0 auto;
}

.App header {
  background-color: #43616F;
  color: white;
  width: 100%;
  max-width: 728px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
}


.Main {
  background-color: #304955;
  color: white;
  width: 100%;
  max-width: 728px;
  top: 0;
  padding: 25px;
  margin: '0 16px';
}

.Main a {
  color: white;
}


.TopLeft h2 {
  color: white;
}

.TopCenter h3 {
  color: white;
  line-height: 1;  
}

